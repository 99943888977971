html {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
}
body {
  margin:0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 16px;
  /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.5;
  font-weight: 400;
  color: var(--color);
  background-color: var(--fondo);
}
#root{
  min-height: 100%;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


/* estructura */
.libro{
  min-height: 100vh;
}
.topnav{
  min-height: 4rem;
  padding: 0 1.5rem;
  background-color: var(--fondo);
  box-shadow: var(--sombra);

  position: sticky;
  left: 0; top: 0; right: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
@media screen and (max-width: 860px){
  .topnav{
    padding: 0 1rem;
    flex-wrap: wrap;
  }
}
.topnav__nav{
  display: flex;
  align-items: center;
}
.btn-volver{
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  margin: .5rem 0;
  background: #ffffff url("/src/img/icono-volver-24px.png") no-repeat center;
  border: none;
  border-radius: 100%;
  box-shadow: 0 0 4px #4141412d;
}
.btn-volver:hover,
.btn-volver:focus{
  background: var(--eroski) url("/src/img/icono-volver-24px-blanco.png") no-repeat center;
}
.breadcrumb{
  margin: 0 .5rem;
}
@media screen and (max-width: 600px) {
  .breadcrumb{
    margin-right: 3rem;
  }
}
.breadcrumb > a{
  margin: 0 .5rem;
  cursor: pointer;
  display: inline-block;
  border-bottom: 2px solid transparent; /*evitar desplazamiento indeseado en hover/focus*/
}
.breadcrumb__portada{
  font-weight: 600;
}
.breadcrumb__activo, .txt-rojo{
  color: var(--eroski);
}
.breadcrumb > a:hover,
.breadcrumb > a:focus{
  border-bottom: 2px solid var(--eroski);
}
.topnav__buscador{
  line-height: 1em;
  margin-left: auto;
  position: relative;
}
.topnav__buscador  input{
  height: 2rem;
  width: 30vw;
  margin: 0;
  padding: .25rem .5rem;
  padding-right: 48px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 4px #bbbbbb;
}
.topnav__buscador input:hover,
.topnav__buscador input:focus{
  outline: none; 
  outline: 2px solid var(--eroski);
}
.topnav__buscador .icono_buscar{
  position: absolute;
  right: 0;
  top: 0;
  height: 2rem;
  width: 2.5rem;
  background-image: url(./img/icono-buscar.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.topnav__buscador input:focus + .icono_buscar{
  background-image: url(./img/icono-buscar-rojo.svg);
}
@media screen and (max-width: 860px) {
  .topnav__buscador{
    margin: 1rem 0;
    width: 100%;
  }
  .topnav__buscador  input{
    width: 100%;
  }
}
.topnav__buscador  input::placeholder{
  color: var(--color);
  font-weight: 400;
  font-style: italic;
  opacity: .5;
  font-size: 16px;
  text-overflow: ellipsis;
}
.topnav-logo-eroski{
  width: 140px;
  height: 40px;
  margin: 0.5em 1em;
  background-image: url("/src/img/eroski.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 860px) {
  .topnav-logo-eroski{
    width: 2.5em;
    height: 2.5em;
    background-image: url("/src/img/eroski-isotipo.png");
    position: absolute;
    right: 0;
    top: 0;
  }
}
.sidenav{
  position: fixed;
  top: 0; bottom: 0; left: 0;
  width: 20rem;
  padding: 5rem 1rem 1rem;
  background-color: #DEDEDE;
  overflow-y: auto;
	box-shadow: 0 0 8px inset #aaa;
}
@media screen and (max-width: 860px) {
  .sidenav{
    width: 100%;
    padding-top: 1rem;
    display: none;
    z-index: 3;
    position: inherit;
  }
}
.btn-cerrar{
  display: none;
  margin-left: auto;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  padding: 0;
  background: url("/src/img/icono-cerrar-24px.png") no-repeat center;
  border: none;
  border-radius: 100%;
}
@media screen and (max-width: 860px) {
  .btn-cerrar{
    display: block;
  }
}
.sidenav__selector{
  margin: 1rem 0 2rem;
}
.sidenav__selector > select{
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0 .5rem;
}
.sidenav__selector > *{
  display: block;
  width: 100%;
  margin-bottom: .5rem;
}
.sidenav__menu__sistema{
  width: 100%;
  margin: 1rem 0;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
}
.sidenav__menu__sistema__titulo{
  line-height: 1em;
  padding: 1rem;
  border-radius: 8px;
  background: #eeeeee;
  text-transform: uppercase;
  cursor: pointer;

  display: flex; /*situar respecto a flecha*/
  justify-content: space-between;
  align-items: center;
}
.sidenav__menu__sistema__titulo:hover{
  background: #808080;
  color: #ffffff;
}
.sidenav__menu__sistema__titulo:hover .flecha-acordeon{
  border-color: #ffffff;
}
.sidenav__menu__subsistema{
  padding: .5rem 1rem;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidenav__menu__subsistema:not(.sidenav__menu__subsistema:last-of-type){
  border-bottom: 1px solid #eeeeee;
}
.sidenav__menu__subsistema:last-of-type{
  border-radius: 0 0 8px 8px; /*para el efecto hover*/
}
.sidenav__menu__subsistema.subsistema-activo,
.sidenav__menu__subsistema:hover{
  background: #f5f5f5;
}
.sidenav__menu__subsistemas{
  display: none;
}
/* sistema activo */
.sidenav__menu__sistema.sistema-activo .sidenav__menu__sistema__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
}
.sidenav__menu__sistema.sistema-activo .sidenav__menu__subsistemas{
  display: block;
}
.sidenav__menu__sistema.sistema-activo .flecha-acordeon{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  transform: rotate(-135deg);
}
/* frio */

.sidenav__menu__subsistema.frio::after{
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("/src/img/icono-frio.svg") no-repeat center;
  background-size: contain;
}
.main__tarjeta__titulo.frio::after{
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: url("/src/img/icono-frio-blanco.svg") no-repeat center;
  background-size: contain;
  margin-left: 15px;
}
.main__tarjeta__equipo.frio::before{
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: .5rem;
  background: url("/src/img/icono-frio.svg") no-repeat center;
  background-size: contain;
}
.historial__equipo__titulo.frio::before{
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: .5rem;
  background: url("/src/img/icono-frio-blanco.svg") no-repeat center;
  background-size: contain;
}

/* inversiones futuras */
.equipo__semaforo{
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  margin-right: 1rem;
}
.equipo__semaforo > .equipo__semaforo__indicador{
  width: 1rem;
  height: 1rem;
  /* background-color: #dbdbdb; */
  border-radius: 100%;
  box-shadow: 1px 1px 4px #afafaf;
}
.equipo__semaforo > .equipo__semaforo__indicador.rojo,
.ficha__semaforo.rojo{
  background-color: crimson;
}
.equipo__semaforo > .equipo__semaforo__indicador.ambar,
.ficha__semaforo.ambar,
.equipo__semaforo > .equipo__semaforo__indicador.amarillo,
.ficha__semaforo.amarillo{
  background-color: orange;
}
.equipo__semaforo > .equipo__semaforo__indicador.verde,
.ficha__semaforo.verde{
  background-color: mediumseagreen;
}
/* MAIN */
.main{
  margin-left: 20rem;
  padding: 2rem 1rem;
}
.main.main__plataformas{
  padding: 0;
  /* para que el mapa ocupe todo el espacio hasta el borde */
}
@media screen and (max-width: 860px) {
  .main{
    margin-left: 0;
    padding: 1rem .5rem;
  }
}
.btn-menu{
  display: none;
  margin: 0 1rem 1rem auto   ; 
   width: 3rem;
  min-width: 3rem; 
  height: 3rem;
  min-height: 3rem;
  padding: .5rem;
  background: #ffffff url("/src/img/icono-menu-24px.png") no-repeat center;
  border: none;
  border-radius: 100%;
  box-shadow: 0 0 4px #4141412d;
}
.container_btn-menu{
  display: none;

  margin: 0 1rem 1rem auto   ;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
}
@media screen and (max-width: 860px) {
  .btn-menu, .container_btn-menu{
    display: block;
  }

}
.main__tarjetas{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.main__tarjeta{
  flex-basis: 40%;
  min-width: 320px;
  flex-grow: 1;
  margin: 1rem;
  margin-left: clamp(0.25rem, 0.04999999999999999rem + 1vw, 1.25rem);
  margin-right: clamp(0.25rem, 0.04999999999999999rem + 1vw, 1.25rem);
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
  overflow-y: hidden;
}
/* @media screen and (min-width: 1250px){
  .main__tarjeta{
    max-width: 50%;
  }
} */
.main__tarjeta__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
  line-height: 1em;
  padding: 1rem;
  text-transform: uppercase;

  display: flex; /*situar respecto a btn-comparar*/
  justify-content: space-between;
  align-items: center;
}
.main__tarjeta__titulo.sin-contenido{
  border-radius: 8px;
}
.main__tarjeta__equipo{
  padding: .5rem 1rem;
  display: flex; /*situar respecto a flecha*/
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cccccc;
  cursor: pointer;
}
.main__tarjeta__equipo > span:first-child{
  margin-right: auto; /* para distribuir correctamente cuando hay icono de frio*/
}
.main__tarjeta__equipo__contenido{
  padding: .5rem 1rem;
  font-weight: 400;
  display: none;
  border-top: 1px solid #eeeeee;
}
.main__tarjeta__equipo__contenido > .ult-act{
  display: block;
  font-size: .75em;
  margin: .5rem 0;
  text-align: right;
}
.main__tarjeta__equipo__contenido > .ult-act > a{
  text-decoration: underline;
  cursor: pointer;
}
/* equipo activo */
.main__tarjeta__equipo.equipo-activo + .main__tarjeta__equipo__contenido{
  display: block;
}
.main__tarjeta__equipo.equipo-activo,
.main__tarjeta__equipo:hover{
  background: #f5f5f5;
}
.main__tarjeta__equipo.equipo-activo .flecha-acordeon{
  transform: rotate(-135deg);
}
/*  */
.archivo-adjunto{
  margin: .5rem 0;
}
.archivo-adjunto > a{
  padding-left: .5rem;
  text-decoration: underline;
  cursor: pointer;
}
.archivo-adjunto::before{
  content: url('/src/img/icono-adjunto.svg');
  display: inline-block;
  width: 18px;
  height: 18px;
}
.link-externo{
  margin: .5rem 0;
}
.link-externo > a{
  padding-left: .5rem;
  text-decoration: underline;
  cursor: pointer;
}
.link-externo::before{
  content: url('/src/img/icono-link.svg');
  display: inline-block;
  width: 16px;
  height: 16px;
}
.main__tarjeta__equipo__contenido img {
  width: 100%;
}
.leaflet-container {
  z-index: 0;
}
/* *********** VARIACIONES PANTALLA DE PLATAFORMAS *********** */
@media screen and (max-width: 860px) {
  .sidenav__plataformas{
    display: block;
  }
  .main__plataformas {
    display: none;
  }
}
.sidenav__plataformas__buscar{
  margin: 1rem 0 2rem;
}
.sidenav__plataformas__buscar> * {
  display: block;
  width: 100%;
  margin-bottom: .5rem;
}
.sidenav__plataformas__buscar > input{
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0 .5rem;
}
.sidenav__plataformas__buscar > input:hover,
.sidenav__plataformas__buscar > input:focus{
  outline: none; 
  outline: 2px solid var(--eroski);
}
.sidenav__plataformas__buscar > input::placeholder{
  color: #808080;
  text-overflow: ellipsis;
}
.sidenav__plataformas__comunidad{
  width: 100%;
  margin: 2rem 0 1rem;
}
.sidenav__plataformas__comunidad__titulo{
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.sidenav__plataformas__comunidad__botones{
  width: 100%;
  display: none;
}
.sidenav__plataformas__comunidad.comunidad-activa > .sidenav__plataformas__comunidad__botones{
  display: block;
}
.sidenav__plataformas__comunidad.comunidad-activa .flecha-acordeon{
  transform: rotate(-135deg);
}
.sidenav__plataformas__comunidad__botones > button{
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem;
  height: 4rem;
  border-radius: 4px;
  border: none;
  font-size: 1.5rem;
  background-color: #868686;
  background-image: url('/src/img/plataformas-placeholder.jpg'); /* provisional */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}
.sidenav__plataformas__comunidad__botones > button:hover{
  background-color: #ffffff;
}

/* iconos flechas */
.flecha-derecha{
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
}
.flecha-derecha::before{
	position: absolute;
	top: 3px; left: 0;
	content: "";
  background: rgb(184, 184, 184);
	width: 12px;
	height: 3px;
	transform: rotate(45deg);
}
.flecha-derecha::after{
	position: absolute;
	top: 10px;
	left: 0;
	content: "";
	background: rgb(184, 184, 184);
	width: 12px;
	height: 3px;
	transform: rotate(-45deg);
}
/* flechas acordeón */
.flecha-acordeon{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  transform: rotate(45deg);
  flex-shrink: 0;
}
.flecha-acordeon__hidden{
  visibility: hidden;
}


/* *********** PARA LA FICHA DE PLATAFORMA ********** */
.ficha__portada{
  width: 100%;
  max-width: 800px;
}
div.ficha__titulo > h1{
  font-size: clamp(2em, 5vw, 3em);
}
.ficha__contenido{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.ficha__tabla{
  flex-grow: 1;
  margin: 1rem;
  text-align: left;
  border-collapse: collapse;
}

.ficha__tabla caption{
  text-align: left;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.ficha__tabla th{
  padding: 1rem;
  vertical-align: top;
  border-bottom: 2px solid #808080;
  font-size: clamp(0.75rem, 0.3409rem + 1.8182vw, 1rem);
}
.ficha__tabla td{
  padding: 1rem;
  vertical-align: top;
  border-bottom: 2px solid #808080;
  font-size: clamp(0.75rem, 0.3409rem + 1.8182vw, 1rem);
}
#ficha__tabla__inversiones-futuras td{
  text-align: center;
}
#ficha__tabla__inversiones-futuras .ficha__semaforo.amarillo,
#ficha__tabla__inversiones-futuras .ficha__semaforo.rojo{
  cursor: pointer;
}
.ficha__semaforo{
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #dbdbdb;
  border-radius: 100%;
  box-shadow: 1px 1px 4px #afafaf;
}
.ficha__tabla__presupuesto{
  /* flex-basis: 45%; */
  margin: 1.8rem 1rem 1rem 1rem;
  text-align: left;
  border-collapse: collapse;
  width: calc(100% - 2rem);
}
.ficha__tabla__presupuesto caption{
  text-align: left;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.ficha__tabla__presupuesto th{
  padding: 1rem;
  vertical-align: top;
  font-size: clamp(0.75rem, 0.3409rem + 1.8182vw, 1rem);
}
.ficha__tabla__presupuesto td{
  padding: 1rem;
  vertical-align: top;
  font-size: clamp(0.75rem, 0.3409rem + 1.8182vw, 1rem);
}
/* MAPA */
.leaflet-container {
  height: 85vh;
  height: calc(100vh - 4rem);
  width: 100%;
}

.mapa-indicador-azul {  
  display: inline-block;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin: 0 4px;
  border-radius: 7px;
  color: #fff;
  font-size: .8rem;
  line-height: 14px;
  text-align: center;
  background: var(--azul-deg-bicolor);
}

.mapa-indicador-naranja { 
  display: inline-block;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin: 0 4px;
  border-radius: 7px;
  color: #fff;
  font-size: .8rem;
  line-height: 14px;
  text-align: center;
  background: var(--naranja-deg-bicolor);
}

.mapa-indicador-gris {  
  display: inline-block;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin: 0 4px;
  border-radius: 7px;
  color: #fff;
  font-size: .8rem;
  line-height: 14px;
  text-align: center;
  background-color: #666;
}
/* *********** MODAL HISTÓRICO ********** */
.modal-historico{
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: #f5f5f5f2;
  z-index: 10;
  overflow-y: auto;
  display: none;
}
.historial__equipo{
  min-width: 320px;
  max-width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
  overflow-y: hidden;
}
.historial__equipo__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
  line-height: 1em;
  padding: 1rem;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.historial__equipo__titulo > span:first-child{
  margin-right: auto; /* para distribuir correctamente cuando hay icono de frio*/
}
.historial__equipo__inversiones{
  display: flex;
  flex-wrap: wrap;
}
.historial__equipo__version{
  padding: .5rem 1rem;
  display: flex; /*situar respecto a flecha*/
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cccccc;
  cursor: pointer;
}
.historial__equipo__version__contenido{
  padding: .5rem 1rem;
  font-weight: 400;
  display: none;
  border-top: 1px solid #eeeeee;
}
/* equipo activo */
.historial__equipo__version.equipo-activo + .historial__equipo__version__contenido{
  display: block;
}
.historial__equipo__version.equipo-activo,
.historial__equipo__version:hover{
  background: #f5f5f5;
}
.historial__equipo__version.equipo-activo .flecha-acordeon{
  transform: rotate(-135deg);
}
/* boton cerrar */
.cerrar{
  display: block;
  position: relative;
  /* right: 0px;
  top: 0; */
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.cerrar::after{
  content: '';
  position: absolute;
  top: 13px;
  right: 5px;

  width: 20px;
  height: 3px;
  background: #ffffff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 1px;
}
.cerrar::before{
  content: '';
  position: absolute;
  top: 13px;
  right: 5px;

  width: 20px;
  height: 3px;
  background: #ffffff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 1px;
}
/* boton abrir */
.abrir{
  display: block;
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
}
.abrir::after{
  content: '';
  position: absolute;
  top: 13px;
  right: 0;

  width: 20px;
  height: 3px;
  background: #ffffff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 1px;
}
.abrir::before{
  content: '';
  position: absolute;
  top: 13px;
  left: 0;

  width: 20px;
  height: 3px;
  background: #ffffff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 1px;
}
.abrir.abierto{
  transform: rotate(180deg);
  transition: transform 300ms ease-in-out;
}

/* *********** MODAL MANTENIMIENTO ********** */
.modal-mantenimiento{
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: #f5f5f5f2;
  z-index: 10;
  display: none;
}
.modal__grupo-botones{
  min-width: 320px;
  max-width: 50%;
  margin: 10vh auto;
}
/* cerrar modal */
.cerrar-modal{
  display: block;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.cerrar-modal::after{
  content: '';
  position: absolute;
  top: 13px;
  right: 5px;

  width: 20px;
  height: 3px;
  background: #808080;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 1px;
}
.cerrar-modal::before{
  content: '';
  position: absolute;
  top: 13px;
  right: 5px;

  width: 20px;
  height: 3px;
  background: #808080;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 1px;
}
/* PANTALLA RESULTADOS */
.main__buscador__tarjeta{
  padding: .5rem 1rem;
}
.main__buscador__tarjeta__ruta{
  font-size: 14px;
  margin: 1rem 0;
}
.main__buscador__tarjeta__nombre{
  font-size: 1.25rem;
  margin: 1rem 0;
  font-weight: 600;
}
.main__buscador__tarjeta__footer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: .5rem 0;
}
.main__buscador__tarjeta__footer > *{
  margin: .5rem 0;
}
.main__buscador__tarjeta__footer button, .boton_comparador{
  padding: .25rem 1rem;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: 2px solid #595959;
  border-radius: .25rem;
  cursor: pointer;
  color: #595959;
}
.boton_comparador {
  background-color: #fff;  
}
.boton_comparador_tarjeta{
  padding: .10rem .5rem;
  border: 2px solid rgb(255, 255, 255);
  color: #fff;
  background: transparent;
  margin-left: auto;
  margin-right: 1rem;
}
.main__buscador__tarjeta__footer button.boton-documentacion{
  background-color: #595959;
  color: #fff;
}
.main__buscador__tarjeta__footer button + button{
  margin-left: 1rem;
  }
.main__buscador__tarjeta__footer button:hover,
.main__buscador__tarjeta__footer button:focus,
.boton_comparador:hover,
.boton_comparador:focus{
  color: #fff;
  border-color: var(--eroski);  
  background-color: var(--eroski);  
}

.main__buscador__tarjeta__footer > .tipo{
  text-align: left;
  padding: .25rem .5rem;
  border-radius: 1rem;
  font-size: 14px;
  margin-right: .5rem;
}
.main__buscador__tarjeta__footer > .tipo.equipo{
  color: #414147;
  background-color: #E4E6EA;
}
.main__buscador__tarjeta__footer > .tipo.equipo::before{
  content: 'Equipo';
}
.main__buscador__tarjeta__footer > .tipo.tarjeta{
  color: #5349E7;
  background-color: #E0E6FF;
}
.main__buscador__tarjeta__footer > .tipo.tarjeta::before{
  content: 'Tarjeta';
}
.main__buscador__tarjeta__footer > .tipo.subsistema{
  color: #2b786e;
  background-color: #cffbf0;
}
.main__buscador__tarjeta__footer > .tipo.subsistema::before{
  content: 'Subsistema';
}
.main__buscador__tarjeta__footer > .tipo.sistema{
  color: #a15b00;
  background-color: #fcf2c3;
}
.main__buscador__tarjeta__footer > .tipo.sistema::before{
  content: 'Sistema';
}

.sidenav .filtro__equipo {
  display: flex;
  align-items: flex-start;
}

.sidenav .filtro__equipo input {
  margin: 4px 4px 0;
}
/* manual de usuario */
button.btn-ayuda,
button.btn-documentacion{
  position: fixed;
  z-index: 3;
  bottom: 1rem;
  right: 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 600;
  padding: .25rem .5rem;
  box-shadow: var(--sombra);
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}
button.btn-ayuda:hover,
button.btn-documentacion:hover{
  background-color: var(--eroski);
  color: #fff;
}
button.btn-ayuda > span{
  display: inline-block;
  margin-right: .5rem;
  width: 1rem;
  height: 1rem;
  background-image: url(/src/img/icono-ayuda.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
button.btn-ayuda:hover > span{
  background-image: url(/src/img/icono-ayuda-blanco.svg);
}
.modal-ayuda{
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: #f5f5f5f2;
  z-index: 10;
  overflow-y: auto;
}
.modal-ayuda__equipo{
  min-width: 320px;
  max-width: 900px;
  margin: 10vh auto;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}
.modal-ayuda__equipo__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
  line-height: 1em;
  padding: 1rem;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-ayuda__equipo__version__contenido{
  padding: .5rem 1.5rem;
  font-weight: 400;
  color: var(--color);
}
.login-page .modal-ayuda__equipo__version__contenido  h1{
  color: var(--color);
}

.modal-ayuda__equipo__version__contenido .btn-onboarding{
  border-radius: 4px;
  border: none;
  padding: 0.25rem 0.5rem;
  box-shadow: var(--sombra);
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--eroski);
  margin-top: 1rem;
}
/* portada modal inversiones futuras */
.modal-inversiones-portada{
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: #f5f5f5f2;
  z-index: 10;
  overflow-y: auto;
}
.modal-inversiones-portada__equipo{
  min-width: 320px;
  max-width: 50%;
  max-height: calc(100vh - 4rem);
  margin: 2rem auto;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
  overflow-y: auto;
}
.modal-inversiones-portada__equipo__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
  line-height: 1em;
  padding: 1rem;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-inversiones-portada__equipo__version__contenido{
  padding: .5rem 1rem;
  font-weight: 400;
  color: var(--color);
  display: flex;
  flex-direction: column;
}
.modal-inversiones-portada__equipo__version__contenido td{
  text-align: center;
}
.modal-inversiones-portada__equipo__version__contenido:not(.modal-inversiones-portada__equipo_historico_info) tbody > tr:first-of-type > th {
  font-size: clamp(0.85rem, 0.3409rem + 1.8182vw, 1.1rem);
}
.modal-inversiones-portada__equipo__version__contenido:not(.modal-inversiones-portada__equipo_historico_info) tbody > tr:first-of-type > th:last-of-type{
  text-align: center;
}
.modal-inversiones-portada__equipo__version__contenido:not(.modal-inversiones-portada__equipo_historico_info) tbody > tr:not(:first-of-type) {
  cursor: pointer;
}
.modal-inversiones-portada__equipo__version__contenido:not(.modal-inversiones-portada__equipo_historico_info) tbody > tr:not(:first-of-type):hover {
  background: #f5f5f5;
}
/* mantenimiento */
.equipo__mantenimiento{
  width: 22px;
  height: 22px;
  margin-right: 1rem;
  padding-right: 20px;
  /* background-image: url(/src/img/mant-ext.svg); */
  border-radius: 50%;
  box-shadow: 1px 1px 4px #afafaf;
}
.equipo__mantenimiento.mant__interno{
  background-image: url(/src/img/mant-eroski.svg);
}
.equipo__mantenimiento.mant__externo{
  background-image: url(/src/img/mant-ext.svg);
}

.ficha__titulo_descargar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div__breadcrumb-modal-inversiones{
  margin-top: 0.5rem;
}
.texto-volver{
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  min-height: 2rem;
  margin: .5rem 0;
  background: #ffffff url("/src/img/icono-volver-24px.png") no-repeat center;
  border: none;
  border-radius: 100%;
  /* box-shadow: 0 0 4px #4141412d; */
}
/* *********** MODAL MANTENIMIENTO ********** */
.modal-mantenimiento{
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: #f5f5f5f2;
  z-index: 10;
  overflow-y: auto;
  display: block;
}
.modal-mantenimiento__equipo{
  min-width: 320px;
  max-width: 50%;
  margin: 2rem auto;
  border-radius: 8px;
  background: #ffffff;
  font-weight: 500;
  box-shadow: var(--sombra);
  overflow-y: hidden;
}
.modal-mantenimiento__equipo__titulo{
  border-radius: 8px 8px 0 0;
  background: #808080;
  color: #ffffff;
  line-height: 1em;
  padding: 1rem;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-mantenimiento__equipo__version__contenido{
  display: flex;
  flex-wrap: wrap;
  padding: .5rem 1rem;
  font-weight: 400;
  border-top: 1px solid #eeeeee;
}
/* @media screen and (max-width: 860px) {
  button.btn-ayuda{
    display: none;
  }
  .login-page button.btn-ayuda{
    display: flex;
  }
} */

.boton-ver__equipo__semaforo{
  width: 3.3rem;
  display: inline-flex;
  align-items: center;
  border-radius: 15px;
  box-shadow: 1px 1px 4px #afafaf;
  margin-right: 1rem;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
}
.boton-ver__equipo__semaforo >.equipo__semaforo {
  width: 22px;
  height: 22px;
  padding: 3px 0.5rem;
}
.boton-ver__equipo__semaforo:hover{
  color: #ffffff;
  background-color: #808080;
}
td > .boton-ver__equipo__semaforo{
  margin-right: 30px;
}
.main__tarjeta__equipos > .main__tarjeta__equipo{
  min-height: 49px;
}
.btn_comparador{
  background-image: url(./img/btn-comp1.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-color: unset;
  height: 1.7rem;
  width: 3rem;
  border: none;
  margin-left: auto;
  cursor: pointer;
}
.btn_comparador >span{
  display: none;
}
.btn_comparador:hover{
  background-image: url(./img/btn-comp2.svg);
  border: none;
  width: 7.5rem;
  padding-right: 20px;
}
.btn_comparador:hover >span{
  display: block;
  color: white;
  margin-right: 15px;
  transition-duration: 0.5ms;
}


.dimmed-portada{
  position: relative;
  /* width: 363px;
  height: 83px; */
}
.dimmed-portada-sombra{
  box-shadow: 0 0 0 1000vmax #ffffffb3;
    border-radius: 12px;
}
.dimmed-portada-margen{
  padding: 1px;
  margin: 0px 6px;
}
.dimmed-portada-ayuda{
  position: absolute;
  right: -340px;
  top: -1rem;
  background: var(--eroski);
  padding: 1rem;
  border-radius: 15px;
  width: 300px;
  z-index: 9;
  color: white;
}
.dimmed-top .dimmed-portada-ayuda{
  right: 2rem;
  top: unset;
  bottom: 5rem;
}
.dimmed-left .dimmed-portada-ayuda{
  left: -322px;
}
.dimmed-bottom .dimmed-portada-ayuda{
  right: 2rem;
  top: 5rem;
}
@media screen and (max-width: 1210px){
  .dimmed-tablet-top .dimmed-portada-ayuda{
    right: 2rem;
    top: unset;
    bottom: 5rem;
    left: unset;
  }
  .dimmed-tablet-bottom .dimmed-portada-ayuda{
    right: 2rem;
    top: 5rem;
    left: unset;
    bottom: unset;
  }
}
@media screen and (max-width: 860px){
  .dimmed-mobile-top .dimmed-portada-ayuda{
    right: 2rem;
    top: unset;
    bottom: 5rem;
    left: unset;
  }
  .dimmed-mobile-bottom .dimmed-portada-ayuda{
    right: 2rem;
    top: 5rem;
    left: unset;
    bottom: unset;
  }
}
.dimmed-portada-ayuda:before {
  content:"";
  position: absolute;
  width: 0;
  height: 0;
  left: -18px;
  top: 2rem;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 18px solid var(--eroski);
  transform: rotate(270deg);
}

.dimmed-top .dimmed-portada-ayuda:before{
  left: unset;
  top: unset;
  bottom: -18px;
  right: 2rem;
  transform: rotate(180deg);
}
.dimmed-left .dimmed-portada-ayuda:before{
  left: unset;
  right: -18px;
  transform: rotate(90deg);
}
.dimmed-bottom .dimmed-portada-ayuda:before{
  left: unset;
  top: -18px;
  right: 2rem;
  transform: rotate(0deg);
}
@media screen and (max-width: 1210px){
  .dimmed-tablet-top .dimmed-portada-ayuda:before{
    left: unset;
    top: unset;
    bottom: -18px;
    right: 2rem;
    transform: rotate(180deg);
  }
  .dimmed-tablet-bottom .dimmed-portada-ayuda:before{
    left: unset;
    top: -18px;
    right: 2rem;
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 860px){
  .dimmed-mobile-top .dimmed-portada-ayuda:before{
    left: unset;
    top: unset;
    bottom: -18px;
    right: 2rem;
    transform: rotate(180deg);
  }
  .dimmed-mobile-bottom .dimmed-portada-ayuda:before{
    left: unset;
    top: -18px;
    right: 2rem;
    transform: rotate(0deg);
  }
}
.dimmed-portada-ayuda-titulo{
  text-transform: uppercase;
  font-size: 1.3rem;
    line-height: 1.7rem;
}
.login-page .dimmed-portada-ayuda-titulo{
  margin: 0;
}
.dimmed-portada-linea1{
  display: flex;
  justify-content: space-between;
}
.dimmed-portada-linea3{
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.dimmed-portada-ayuda-descripcion{
  font-weight: 300;
  padding: 0.5rem 0;
  line-height: 1.5;
}
.dimmed-portada-ayuda-saltar{
  background: none;
  color: white;
  border: unset;
  font-weight: 100;
  cursor: pointer;
  pointer-events: auto;
}
.dimmed-portada-ayuda-siguiente{
  background: white;
  color: var(--eroski);
  border: unset;
  border-radius: 7px;
  font-size: 0.8rem;
  font-weight: 300;
  cursor: pointer;
  pointer-events: auto;
  padding: 0.4em;
}
.dimmed-portada-ayuda-anterior{
  pointer-events: auto;
  background: transparent;
  border: unset;
  color: white;
  font-size: 1.4rem;
  margin-right: 1.5rem;
  background-image: url("/src/img/btn-anterior.svg");
  background-repeat: no-repeat;
  margin-bottom: -1.1rem;
  width: 0.9rem;
  height: 2rem;
  display: inline-flex;
  cursor: pointer;
}
.dimmed-portada-ayuda-paginacion{
  font-weight: 100;
  opacity: 0.7;
}
.page-dimmed{
  pointer-events: none;
}
.page-dimmed + .btn-ayuda{
  pointer-events: none;
  z-index: -1;
}
.page-dimmed .topnav{
  /* box-shadow: 0 0 0 100vmax #ffffffb3; */
  /* background: #ffffffb3; */
  opacity: 0.5;
}
.page-dimmed .topnav.topnav-no-dimmed{
  opacity: unset;
}
.page-dimmed .topnav.topnav-no-dimmed .dimmed-portada  + .topnav__buscador{
  z-index: -1;
}
/* estilos especificos por pagina */

.login-page.page-dimmed .portada-nav,
.login-page.page-dimmed .formulario,
.login-page.page-dimmed .login-logo-eroski{
  z-index: -1;
}
@media screen and (max-width: 860px){

  .login-page.page-dimmed .portada-nav .dimmed-mobile-top .dimmed-portada-ayuda{
    bottom: 7rem;
  }
  .login-page.page-dimmed .portada-nav .dimmed-mobile-bottom .dimmed-portada-ayuda,
    .login-page.page-dimmed .login-area > .dimmed-portada.dimmed-mobile-bottom .dimmed-portada-ayuda{
    top: 7rem;
  }
}

@media screen and (max-width: 420px){
  .login-page.page-dimmed .portada-nav .dimmed-mobile-top .dimmed-portada-ayuda,
  .login-page.page-dimmed .portada-nav .dimmed-mobile-bottom .dimmed-portada-ayuda,
  .login-page.page-dimmed .login-area > .dimmed-portada.dimmed-mobile-bottom .dimmed-portada-ayuda{
    right: 0;
  }
}

.libro.page-dimmed .sidenav.sidenav__plataformas,
.page-dimmed .sidenav,
.page-dimmed .modal-inversiones-portada__equipo
{
  overflow-y: unset;
}
.libro.page-dimmed .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{
  z-index: -1;
}
.libro.page-dimmed .dimmed-portada-ayuda{
  top: 8rem;
}
@media screen and (max-width: 860px){
  .libro.page-dimmed .dimmed-portada-ayuda{
    top: -4rem;
    bottom: unset;
  }
}

@media screen and (max-width: 860px){
  .page-dimmed .libro .ficha > .ficha__contenido > .dimmed-portada.dimmed-mobile-top  .dimmed-portada-ayuda{
    top: -17rem;
    bottom: unset;
  }
  .page-dimmed .libro #ficha__tabla__inversiones-futuras + .dimmed-portada.dimmed-mobile-top .dimmed-portada-ayuda{
    top: -14rem;
    bottom: unset;
  }
}
.page-dimmed .ficha__contenido > .dimmed-portada {
  flex-grow: 1;
}
.page-dimmed .ficha__contenido > .dimmed-portada .ficha__tabla{
  width: calc( 100% - 2rem);
}
.page-dimmed .main__tarjetas > .dimmed-portada {
  flex-basis: 40%;
  min-width: 320px;
  flex-grow: 1;
}
@media screen and (min-width: 1210px){
  .page-dimmed .main__tarjetas > .dimmed-portada {
    max-width: 50%;
  }
  .page-dimmed .main__tarjetas > .dimmed-portada .main__tarjeta {
    max-width: unset;
  }
}
.modal-inversiones-portada.page-dimmed  .dimmed-portada{
  min-width: 320px;
  max-width: 50%;
  max-height: calc(100vh - 4rem);
  margin: 2rem auto;
}
.modal-inversiones-portada.page-dimmed .modal-inversiones-portada__equipo .dimmed-portada-sombra {
  max-height: calc(100vh - 4rem);
  overflow: hidden;
}
.modal-inversiones-portada.page-dimmed .modal-inversiones-portada__equipo {
  min-width: unset; 
  max-width: unset;
  max-height: unset;
  margin: 1rem auto;
}
.modal-inversiones-portada.page-dimmed + .login-bg {
  pointer-events: none;
}
.page-dimmed .libro .container_btn-menu .dimmed-portada-sombra{
  margin-left: -6px;
  margin-right: -6px;
}
@media screen and (max-width: 860px){
  .page-dimmed .libro .container_btn-menu .dimmed-portada-ayuda{
    right: -1rem;
  }
}
@media screen and (max-width: 860px){
  .page-dimmed .libro.page-detalle > .sidenav > .dimmed-portada > .dimmed-portada-ayuda{
    display: none;
  }
}
@media screen and (max-width: 1400px){
  .modal-inversiones-portada.page-dimmed .dimmed-tablet-bottom .dimmed-portada-ayuda{
    top: 45%;
      right: 2rem;
      left: unset;
      bottom: unset;
  }
  .modal-inversiones-portada.page-dimmed .dimmed-tablet-bottom .dimmed-portada-ayuda:before{
    left: unset;
    top: -18px;
    right: 2rem;
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 860px){
  .modal-inversiones-portada.page-dimmed .dimmed-mobile-bottom .dimmed-portada-ayuda{
    top: 45%;
  }
}
@media screen and (max-width: 1210px){
  .main__tarjetas > .dimmed-tablet-bottom .dimmed-portada-ayuda{
    right: 2rem;
    top: unset;
    left: unset;
    /* bottom: -180px; */
  }
}
@media screen and (max-width: 860px){
  .main__tarjetas > .dimmed-mobile-top .dimmed-portada-ayuda{
    top: -13rem;
    right: 2rem;
    bottom: unset;
    left: unset;
  }
}

/* textos onboarding */
.onboarding-imagen-texto{
  display: flex;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-top: 0.5rem;

}
.onboarding-imagen-texto > img{
  max-width: 50%;
} 
 .onboarding-imagen-texto__texto  span{
  border: 1px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: -1rem;
  background-color: var(--eroski);
  margin-right: 0.3rem;
  text-align: center;
}
.mt-1rem{
  margin-top: 1rem;
}
.mt-05rem{
  margin-top: 0.5rem;
}
.onboarding-imagen{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.onboarding-imagen > *{
  margin-top: 1rem;
}
.onboarding-imagen > img{
  max-width: 90%;
} 
.onboarding-imagen .boton-ver__equipo__semaforo,
.onboarding-botones-tarjeta .boton-ver__equipo__semaforo{
  background-color: var(--fondo);
  color: var(--color);
}
.onboarding-imagen .listado-semaforos >span{
  margin-right: 1rem;
}
.onboarding-botones-tarjeta{
  font-size: 0.9rem;
}
.onboarding-botones-tarjeta > div{
  display: flex;
  padding-top: 1rem;
}
.onboarding-botones-tarjeta > div > div:first-of-type{
  width: 75px;
  min-width: 75px;
  text-align: center;
}
.onboarding-botones-tarjeta > div  img{
  width: 28px;
}
.onboarding-botones-tarjeta .onboarding-imagen-texto__texto {
display: flex;
}
.onboarding-botones-tarjeta .onboarding-imagen-texto__texto  span{
  margin-left: 0rem;
}
.onboarding-botones-tarjeta > div ul{
  list-style: none;
  padding-left: 0px;
  margin: 0.3rem 0.5rem;
}

.onboarding-botones-tarjeta > div ul > li > img{
width: 22px;
margin-right: 0.5rem;
vertical-align: middle;
padding-bottom: 5px;
}
button.btn-documentacion{
  right: 120px;
  color: var(--color);
  font-size: 0.8rem;
  text-decoration: unset;
}
a.btn-ayuda > span{
  display: inline-block;
  margin-right: .5rem;
  width: 1rem;
  height: 1rem;
  background-image: url(/src/img/download2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
a.btn-ayuda:hover > span{
  filter: invert(1);
}
.modal-ayuda__equipo__version__contenido .foto-portada1{
  display: flex;
  justify-content: center;
}
.modal-ayuda__equipo__version__contenido .foto-portada1 > img{
  width: 68%;
}
.modal-ayuda__equipo__version__contenido .main__tarjetas {
  flex-wrap: nowrap;
}
@media screen and (max-width: 860px) {
  .modal-ayuda__equipo__version__contenido .foto-portada1 > img{
    width: 100%;
  }
  .modal-ayuda__equipo__version__contenido .main__tarjetas {
    flex-wrap: wrap;
  }
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas .main__tarjeta{
  min-width: unset;
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas  .main__tarjeta__titulo{
  padding: 0.7rem 1rem;
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas  .main__tarjeta__descripcion{
  padding: 1rem;
  height: 140px;
  font-weight: 100;
  position: relative;
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas .main__tarjeta__btn-tarjeta{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: unset;
  box-shadow: var(--sombra);
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas .main__tarjeta__btn-tarjeta:before{

  width: 10px;
  height: 10px;
  border-right: 3px solid #808080;
  border-bottom: 3px solid #808080;
  transform: rotate( 45deg);
  content: '';
  display: inline-flex;
  margin-bottom: 8px;
  margin-left: 1px;
}
.modal-ayuda__equipo__version__contenido{
  color: var(--color);
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas .main__tarjeta__btn-tarjeta:hover{
  background-color: var(--eroski);
}
.modal-ayuda__equipo__version__contenido  .main__tarjetas .main__tarjeta__btn-tarjeta:hover:before{
  border-color: white;
}
.modal-ayuda__seccion-titulo{
  display: flex;
  align-items: center;
  padding-top: 3rem;
}
#portada .modal-ayuda__seccion-titulo{
  padding: 1rem 0;

}
.modal-ayuda__seccion-titulo h2{
  margin: 0;
  text-transform: uppercase;
}
.modal-ayuda__seccion-titulo .btn-onboarding{
  margin-top: 0rem;
}
.modal-ayuda__seccion-titulo h2 + .btn-onboarding{
  margin-left: 1rem;
}

.modal-ayuda__equipo__version__contenido .item-modelo,
.modal-ayuda__equipo__version__contenido .item-inversiones,
.modal-ayuda__equipo__version__contenido .item-plataformas{
  padding: 1rem 0;
}
.modal-ayuda__equipo__version__contenido   h3{
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem 0;

}
.modal-ayuda__equipo__version__contenido .item-modelo  > div,
.modal-ayuda__equipo__version__contenido .item-plataformas > div ,
  .modal-ayuda__equipo__version__contenido .subitem-plataformas > div {
  display: flex;
  position: relative;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas{
  padding-left: 4rem;
}
@media screen and (max-width: 860px) {
  .modal-ayuda__equipo__version__contenido .item-modelo  > div,
    .modal-ayuda__equipo__version__contenido .subitem-plataformas  > div,
      .modal-ayuda__equipo__version__contenido .item-plataformas  > div {
    flex-direction: column-reverse;
  }
}
.modal-ayuda__equipo__version__contenido .item-modelo  > div > img ,
.modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > img.img_50 ,
.modal-ayuda__equipo__version__contenido .item-plataformas  > div > img {
  width: 50%;
}
.modal-ayuda__equipo__version__contenido .item-plataformas  > div > img.img_60{
  width: 60%;
}

.modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > img {
  width: 45%;

}
@media screen and (max-width: 860px) {
  .modal-ayuda__equipo__version__contenido .item-modelo  > div > img,
  .modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > img,
.modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > img.img_50 ,
.modal-ayuda__equipo__version__contenido .item-plataformas  > div > img.img_60 ,
.modal-ayuda__equipo__version__contenido .item-plataformas  > div > img {
  
    width: 100%;
  }
}
.modal-ayuda__equipo__version__contenido .item-modelo  > div > div > * ,
  .modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > div > * {
    margin: 1rem;
    font-weight: 200;
}
@media screen and (max-width: 860px) {
  .modal-ayuda__equipo__version__contenido .item-modelo  > div > div > * ,
  .modal-ayuda__equipo__version__contenido .subitem-plataformas  > div > div > * {
    margin-left: 0;
    margin-right: 0;
}
}
.modal-ayuda__equipo__version__contenido p{
  font-weight: 200;
}
.modal-ayuda__equipo__version__contenido .item-inversiones{
  display: flex;
  flex-direction: column;
}

.modal-ayuda__equipo__version__contenido .subitem-plataformas  > h4{
  margin: 2rem 0 0;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas  > span{
padding: 1rem 0;
font-weight: 200;
display: flex;
}
@media screen and (min-width: 860px) {

  .modal-ayuda__equipo__version__contenido .item-modelo.equipo  > div > img {
    width: 60%;
  }
  .modal-ayuda__equipo__version__contenido .item-modelo.equipo  > div > div {
    position: absolute;
    left: 50%
  }
  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div {
    flex-direction: column;
  }
  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div > img {
    width: 80%;
  }
  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div .paso1{
    position: absolute;
    left: 50%;
  }
  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div .paso2{
    padding-left: 50%;
  }

}
@media screen and (max-width: 860px) {

  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div > img {
    order: 0;
  }
  .modal-ayuda__equipo__version__contenido .item-plataformas.organizacion  > div .paso1{
    order: 1;
  }
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas .listado-semaforos{
  padding: 0 1rem 1rem;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas .listado-semaforos > div{
  display: flex;
  align-items: center;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas .listado-semaforos > div > span {
  margin-right: 0.5rem;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas > div ul.listado-mantenimiento{
  list-style: none;
}

.modal-ayuda__equipo__version__contenido .subitem-plataformas > div ul.listado-mantenimiento > li > img{
  width: 22px;
  vertical-align: middle;
  margin-bottom: 5px;
  margin-right: 0.5rem;
  
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas h4 > *{
  margin-left: 0.7rem;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas h4 > .icono-frio{
  filter: invert(0.4);
  width: 22px;
  vertical-align: text-top;
}
.modal-ayuda__equipo__version__contenido .icono-comparador{
  width: 22px;
  box-shadow: var(--sombra);
  vertical-align: text-top;
  background: linear-gradient(-45deg, #00000022, transparent);
}
.modal-ayuda__equipo__version__contenido .icono-manten{
  width: 22px;
  box-shadow: var(--sombra);
  vertical-align: text-top;
  border-radius: 50%;;
}
.modal-ayuda__equipo__version__contenido .item-plataformas.info-equipo ol > li {
  font-weight: 200;
  padding-bottom: 0.5rem;
}
.modal-ayuda__equipo__version__contenido .subitem-plataformas  li {
  padding-bottom: 0.5rem;

}