@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

:root{
	--azul: #29abe2;
    --rojo: #e82d2d;
    --naranja: #f17d24;
	--fondo: #eeeeee;
	--color: #323232;
	--h-color: #252525;
	--eroski: #CC2229;
	--sombra: 2px 2px 4px #4141412d;
	--radius: 8px;
	--azul-deg-bicolor: linear-gradient(270deg,var(--azul),#4deeff);
    --naranja-deg-bicolor: linear-gradient(270deg,var(--naranja),#ffcc3e);
}
*{
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	letter-spacing: 0rem;
}
h1, h2, h3, h4, h5, h6{
	margin: 0;
	letter-spacing: 0rem;
	color: var(--h-color);
}
h1{
	font-weight: 500;
	font-size: 3rem;
	text-align: left;
	margin: 8px 0;
}
h2{
	margin: 0 0 6px 0;
	font-weight: 500;
	font-size: 1.2rem;
	text-align: left;
}
h3{
	font-size: 2.6rem;
	line-height: 1.3;
}
h4{
	margin: 0.5em 0.5em 1em;
    font-size: 1rem;
    font-weight: 500;
}
h5{
    font-size: 0.6em;
	font-weight: 300;
}
h6{
	font-size: 1.8rem;
	font-weight: 300;
	color: inherit;
}

.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
