.login-page{
  color: #ffffff;
}
.login-bg{
  height: 100vh;
  background: url("../img/foto-portada.jpg");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}
.login-area{
  height: 100%;
  width: 35%;
  width: clamp(405px, 35%, 40%);
  padding: 3rem 4rem;
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.login-area-blur{
  position: absolute;
  top: -20px; right: -20px; bottom: -20px; left: -20px;
  z-index: -1; /*situar tras el contenido de .login-area*/
  background: url("../img/foto-portada.jpg");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: blur(8px);
  clip-path: inset(15px); /* nitidez borde de blur*/
}
@media screen and (max-width: 860px) {
  .login-area{
    width: 100%;
    padding: 3rem 2rem;
  }
}
.login-page h1{
  font-weight: 300;
  color: #ffffff;
  margin: 1rem 0;
  font-size: clamp(2rem, 1.6772rem + 1.5190vw, 3.5rem);
  line-height: clamp(2rem, 1.5696rem + 2.0253vw, 4rem);
}
.formulario{
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.formulario > * {
  margin-bottom: 1rem;
}
.login-page label{
  margin: .5rem 0;
}
.login-page input {
  width: 100%;
  height: 2.5rem;
  padding: .1rem 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #ffffff;
  border:1px solid rgba(255, 255, 255, 0.459);
  background-color: #ffffff2f;
}
input::placeholder{
  color: #ffffff;
  opacity: 1;
  font-weight: 200;
}
input:focus::placeholder{
  opacity: 0;
}
.login-button {
  margin: 1rem 0;
  padding: 1rem 2rem;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5rem;
  background-color:#ffffff;
  color: var(--color);
  border: none;
  box-shadow: var(--sombra);
  border-radius: var(--radius);
  text-align: left;
  display: flex; /*situar respecto a flecha*/
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
}
.link-externo-portada{
  height: 1rem;
  margin-left: .5rem;
  margin-right: auto;
}
.login-logo-eroski{
  width: 140px;
  height: 40px;
  margin: 0.5em 1em;
  background-image: url("/src/img/eroski.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0; /* para que no se vuelva minúsculo en móviles*/
}
@keyframes arrow {
  0% {transform: translate(0px);}
  60% {transform: translate(16px);}
  100% {transform: translate(0px);}
}
@media screen and (prefers-reduced-motion: no-preference){
  .login-button:hover > .flecha-derecha{
    /* transition: all linear 200ms;
    margin-right: -1rem; */
    animation-name: arrow;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.login-button:hover{
  transform: scale(1.05);
  transition: transform 300ms ease-in-out;
}
/*======================================
    >buscador
======================================*/
.login-page .componenteBuscador > button{
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 0;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
  background-image: url(/src/img/icono-buscar.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.login-page > .login-bg > .login-area > .formulario > .componenteBuscador > input{
  padding-right: 3rem;
  text-overflow: ellipsis;
}
/*======================================
    >animaciones
======================================*/
@keyframes slide-up {
	from {
	  opacity: 0;
	  -webkit-transform: translateY(40px);
	          transform: translateY(40px);
	}
	to {
	  opacity: 1;
	  -webkit-transform: translateY(0);
	          transform: translateY(0);
	}
  }
.login-area > *:not(.login-area-blur){
  animation-name: slide-up;  
  animation-duration: 400ms;
  animation-delay: 0;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(40px);
}
.login-area > *:nth-child(2){
  animation-delay: 100ms;
}
.login-area > *:nth-child(3){
  animation-delay: 200ms;
}
.login-area > *:nth-child(4){
  animation-delay: 300ms;
}