.listaResultadosBuscador {
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    color: var(--h-color);
    display: flex;
    flex-direction: column;
    padding: .5rem;
    margin: .25rem 0;
    max-height: 480px;
    overflow-y: auto;
    max-height: 40vh;
  }
  .listaResultadosBuscador li {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: .25rem .5rem;
    cursor: pointer;
  }
  .listaResultadosBuscador li:hover,
  .listaResultadosBuscador li.cursorActivo{
    background-color: var(--fondo);
  }
  .listaResultadosBuscador span.valor {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .listaResultadosBuscador span.nombre {
    text-align: left;
    margin-left: 1rem;
    padding: .25rem .5rem;
    border-radius: 1rem;
    font-size: 14px;
  }
  .componenteBuscador .resultadosBuscador {
    display: block;
    position: absolute;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    white-space: normal;
    text-align: left;
    text-transform: none;
    background: white;
    margin-top: .1em;
    width: auto;
    border-radius: .28571429rem;
    -webkit-box-shadow: var(--sombra);
    box-shadow: var(--sombra);
    z-index: 999;
    max-width: 50vw;
  }

  .botonMostrarResultados {
    display: flex;
    justify-content: center;
    margin: 1em 0;
    cursor: pointer;
    color: var(--eroski);
    font-weight: bold;
  }
  .resultadosBuscador > hr{
    border: 1px solid #E4E4E4;
    margin: .25rem 1rem;
  }
  div.noResultados{
    padding: .5rem;
    display: flex;
    align-items: flex-start;
  }
  div.noResultados > img{
    width: 3rem;
    margin-right: .5rem;
  }
  div.noResultados > div > p{
    line-height: 1.25;
    margin: 0 0 .25rem 0;
  }
  div.login-area .noResultados p{
    color: var(--h-color);
  }

  @media screen and (max-width: 860px){
    .componenteBuscador .resultadosBuscador{
      width: 100%;
      max-width: 100%;
    }
  }
 
  @media screen and (max-width: 600px) {
    .componenteBuscador .resultadosBuscador{
      max-width: 100%;
      font-size: 14px;
    }
    /* .listaResultadosBuscador{
      max-height: 300px;
    } */
    .listaResultadosBuscador span.valor{
      font-size: 14px;
    }
  }